import { Details, HindisvikWrapper } from './styles';
import React, { useContext } from 'react';

import { Container } from 'components/common';
import { LangContext } from 'providers/LangProvider';

export const Hindisvik = () => {
  const { lang } = useContext(LangContext);

  const headerText = lang === 'is' ? 'Jörðin' : 'The Farm';

  return (
    <div id="hindisvik">
      <HindisvikWrapper as={Container}>
        <Details>
          <h1>{headerText}</h1>
          {lang === 'is' ? (
            <>
              <p>
                Jörðin Hindisvík stendur yst á Vatnsnesi í Húnaþingi vestra og er kennd við samnefnda vík. Hún er falleg
                annesjajörð í miðjum Húnaflóa með sjónlínu beint í átt að norðurheimskautsbaug. Næsti kaupstaður,
                Hvammstangi, er miðstöð byggðarlagsins.
              </p>
              <p>
                <strong>Hindisvík er í einkaeign og eina jörðin á Íslandi sem ber þetta nafn.</strong>
              </p>
            </>
          ) : (
            <>
              <p>
                Hindisvík is a privately owned farm on the tip of Vatnsnes which is a peninsula on the northwest coast
                of Iceland. It is situated in the middle of Húnaflói with a clear line of vision towards the Arctic
                Circle. Hvammstangi, the nearest town to Hindisvík, is the administrative centre of the area.
              </p>
              <p>
                <strong>
                  Hindisvík, as already stated, is privately owned and the only farm in Iceland bearing the name
                  Hindisvík.
                </strong>
              </p>
            </>
          )}
        </Details>
      </HindisvikWrapper>
    </div>
  );
};
