import React, { useState } from 'react';

import Img from 'react-cool-img';

const Image = ({ className = null, style = null, src, placeholder, alt, useBlurUp = false, height }) => {
  const [loaded, setLoaded] = useState(false);

  return (
    <Img
      className={className}
      src={src}
      placeholder={placeholder}
      style={{ ...style }}
      // style={loaded ? { ...style } : useBlurUp ? { filter: 'blur(15px)', ...style } : { ...style }}
      alt={alt}
      onLoad={() => setLoaded(true)}
      height={height}
    />
  );
};
export default Image;
