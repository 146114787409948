import { Eigendur, Hindisvik, Nu, Sogubrot } from 'components/landing';
import { Layout, SEO } from 'components/common';

import { Header } from 'components/theme';
import Image from '../components/common/Image';
import React from 'react';
import { Thumbnail } from 'components/landing/Hindisvik/styles';
// import contact from 'assets/illustrations/contact.svg';
import mynd1 from 'assets/thumbnail/hvik_pabbi_1.jpg';
import mynd1Loading from 'assets/thumbnail/hvik_pabbi_1_loading.jpg';
import mynd2 from 'assets/thumbnail/hvik_pabbi_2.jpg';
import mynd2Loading from 'assets/thumbnail/hvik_pabbi_2_loading.jpg';

export default () => (
  <Layout>
    <SEO />
    <Header />
    <Hindisvik />
    <Thumbnail>
      <Image src={mynd1} placeholder={mynd1Loading} alt="Hindisvík" useBlurUp height="1000" />
    </Thumbnail>
    <Eigendur />
    <Thumbnail>
      <Image src={mynd2} placeholder={mynd2Loading} alt="Fugl" useBlurUp height="1000" />
    </Thumbnail>
    <Sogubrot />
    <Nu />
  </Layout>
);
