import styled from 'styled-components';

export const Flex = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: 992px) {
    flex-direction: row;
  }

  &.reverse {
    flex-direction: column-reverse;

    @media (min-width: 992px) {
      flex-direction: row;
    }
  }
`;
