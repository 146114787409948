import { Details, HindisvikWrapper } from '../Hindisvik/styles';
import React, { useContext } from 'react';

import { Container } from 'components/common';
import { LangContext } from 'providers/LangProvider';

export const Eigendur = () => {
  const { lang } = useContext(LangContext);

  const headerText = lang === 'is' ? 'Eigendur' : 'Owners';

  return (
    <div id={lang === 'is' ? 'eigendur' : 'owners'}>
      <HindisvikWrapper as={Container}>
        <Details>
          <h1>{headerText}</h1>
          {lang === 'is' ? (
            <>
              <p>
                Eigendur Hindisvíkur eru beinir afkomendur hjónanna Jóns Sigurðssonar og Margrétar Jóhannesdóttur sem
                komu í Vík árið 1837.
              </p>
              <p>
                Þeir eru: <b>Anna Norland</b> og <b>Helga Norland</b>, dætur og erfingjar Gunnars Norland og Jósefínu
                Norland; <b>Kristín Norland</b>, <b>Jón Norland</b> og <b>Halla Norland</b>, börn og erfingjar Sverris
                Norland og Margrétar Norland.
              </p>
            </>
          ) : (
            <>
              <p>
                The owners of Hindisvík are direct ancestors of Jón Sigurðsson and Margrét Jóhannesdóttir who settled
                down in Hindisvík in 1837.
              </p>
              <p>
                The present owners are: <b>Anna Norland</b> and <b>Helga Norland</b>, daughters and heirs of Gunnar
                Norland and Jósefína Norland; <b>Kristín Norland</b>, <b>Jón Norland</b> and <b>Halla Norland</b>,
                children and heirs of Sverrir Norland and Margrét Norland.
              </p>
            </>
          )}
        </Details>
      </HindisvikWrapper>
    </div>
  );
};
