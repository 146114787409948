import styled from 'styled-components';

export const HindisvikWrapper = styled.div`
  padding: 4rem 0;

  @media (min-width: 992px) 
    // padding: 8rem 0;
  }

  h1 {
    margin-bottom: 2rem;
    font-size: 2.25rem;
    color: #333333;

    @media (min-width: 992px) {
      font-size: 3rem;
    }
  }
`;

export const Details = styled.div`
  flex: 1;
  width: 100%;
  margin: 0;
  font-size: 1.125rem;
  line-height: 1.75em;
  color: #333333;

  @media (min-width: 992px) {
    font-size: 1.25rem;
    max-width: 50%;
    margin: 0 auto;
  }

  blockquote {
    color: #1a1a1a;
    font-family: Georgia, Times, 'Times New Roman', serif;
    font-size: 1.25em;
    font-style: italic;
    line-height: 1.8em;
    padding: 0;
    margin: 0;
    position: relative;
    transition: 0.2s border ease-in-out;
    z-index: 0;

    p {
      margin-bottom: 1rem;
    }
  }
`;

export const Thumbnail = styled.div`
  flex: 1;
  width: 100%;

  &.pl {
    @media (min-width: 992px) {
      padding-left: 6rem;
    }
  }

  &.pr {
    @media (min-width: 992px) {
      padding-right: 6rem;
    }
  }

  img {
    width: 100%;
    height: 80vh;
    max-height: 250px;
    object-fit: cover;
    margin-bottom: 0;

    &.small {
      border-radius: 1rem;

      @media (min-width: 576px) {
        max-height: 400px;
      }
    }

    @media (min-width: 576px) {
      max-height: 500px;
    }

    @media (min-width: 992px) {
      max-height: 1000px;
    }
  }
`;
