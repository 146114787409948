import { Details, HindisvikWrapper, Thumbnail } from '../Hindisvik/styles';
import React, { useContext } from 'react';

import { Container } from 'components/common';
import { Flex } from './styles';
import Image from '../../common/Image';
import { LangContext } from 'providers/LangProvider';
import t1 from 'assets/thumbnail/hvik_tommi_1.jpg';
import t2 from 'assets/thumbnail/hvik_tommi_2.jpg';

export const Nu = () => {
  const { lang } = useContext(LangContext);

  return (
    <div
      id={lang === 'is' ? 'nu' : 'the-present'}
      style={{ fontSize: '1.25rem', lineHeight: '1.75em', color: '#333333' }}
    >
      {/* <HindisvikWrapper as={Container}>
        <Details>
          
        </Details>
      </HindisvikWrapper> */}
      <HindisvikWrapper as={Container}>
        <h1>{lang === 'is' ? 'Nú' : 'The Present'}</h1>
        <Flex>
          <Details>
            {lang === 'is' ? (
              <div>
                <p>
                  Hindisvík er náttúruverndarjörð og lokuð fyrir allri umferð ferðamanna og annarra óviðkomandi, meðal
                  annars til að vernda dýralíf og viðkvæman gróður.
                </p>
                <p>
                  Undanfarin ár hefur landselum í látrinu fækkað umtalsvert. Samkvæmt válista Náttúrufræðistofnunar er
                  landselur við Ísland í bráðri útrýmingarhættu. Honum hefur fækkað um rúmlega 75% frá því að talningar
                  hófust árið 1980.
                </p>
              </div>
            ) : (
              <div>
                <p>
                  Hindisvík is a preservation area and closed for all tourists and other unauthorized traffic, primarily
                  to preserve wildlife and delicate vegetation.
                </p>
                <p>
                  Over the last years, the number of common seal in the haul-out site of Hindisvík has decreased
                  rapidly. According to The List of Threatened Species made by Náttúrufræðistofnun Íslands (The
                  Icelandic Institute of Natural History), the common seal around Iceland is severely threatened with
                  extinction. Its number has decreased by 75% since the beginning of systematic counting of seals in
                  1980.
                </p>
              </div>
            )}
          </Details>
          <Thumbnail className="pl">
            <Image className="small" alt="Selir í hindisvík" src={t1} height="500" />
          </Thumbnail>
        </Flex>
      </HindisvikWrapper>
      <HindisvikWrapper as={Container} style={{ paddingTop: 0 }}>
        <Flex className="reverse">
          <Thumbnail className="pr">
            <Image className="small" alt="Selir í hindisvík" src={t2} height="500" />
          </Thumbnail>
          <Details>
            {lang === 'is' ? (
              <div>
                <p>
                  Selurinn í Hindisvík er alfriðaður og hefur verið það marga áratugi. Selasetur Íslands, í samráði við
                  landeigendur, stendur fyrir rannsóknum á sel í Hindisvík auk árlegrar talningar. Því er mikilvægt að
                  menn virði lokunina og fari ekki inn á rannsóknarsvæðið. Með því leggja allir sitt af mörkum og gera
                  vísindamönnum kleift að afla vitneskju um náttúrulega hegðun og útbreiðslu sela til lengri tíma.
                </p>
                <p style={{ fontWeight: 'bold' }}>Sýnið því umhverfinu virðingu.</p>
              </div>
            ) : (
              <div>
                <p>
                  The seals in Hindisvík are under total conservation and have been so for many decades. Selasetur
                  Íslands (The Icelandic Seal Center) has in co-operation with the owners been conducting a study of the
                  seal colony in Hindisvík for many years now. For this reason it is essential that the closure of
                  Hindisvík be respected. By doing so people are helping to assist scientists in gaining a long-term
                  knowledge on the natural behaviour and spread of the common seal.
                </p>
                <p style={{ fontWeight: 'bold' }}>Please take this into consideration!</p>
              </div>
            )}
          </Details>
        </Flex>
      </HindisvikWrapper>
    </div>
  );
};
