import { Details, HindisvikWrapper } from '../Hindisvik/styles';
import React, { useContext } from 'react';

import { Container } from 'components/common';
import { LangContext } from 'providers/LangProvider';
import { Wrapper } from './styles';

export const Sogubrot = () => {
  const { lang } = useContext(LangContext);

  return (
    <Wrapper id={lang === 'is' ? 'sogubrot' : 'history'}>
      <HindisvikWrapper as={Container}>
        <Details style={{ maxWidth: '750px' }}>
          {lang === 'is' ? (
            <>
              <h1>Sögubrot</h1>
              <div>
                <div>
                  <p>
                    Séra Sigurður Norland, bróðir Jóns Norland læknis, afa núverandi eigenda Hindisvíkur, var þekktur
                    maður á sinni tíð og muna ýmsir núlifandi eftir honum enn.
                  </p>
                  <p>
                    Séra Sigurður keypti Hindisvík af móður sinni Helgu Björnsdóttur vorið 1919. Hann fékk veitingu
                    fyrir prestakallinu Tjörn á Vatnsnesi í lok árs 1922.
                  </p>
                  <p>
                    Séra Sigurður hóf friðun sela í Hindisvík upp úr 1940 en hann var einnig þekktur fyrir að eiga mikið
                    hestastóð af hinu svokallaða Hindisvíkurkyni. Það kyn var landsþekkt en skipulagðri ræktun þess í
                    Hindisvík var hætt fyrir alllöngu. <strong>Þar eru nú engir Hindisvíkurhestar lengur.</strong>
                  </p>
                </div>
                <div>
                  <p>
                    Séra Sigurður var skáldmæltur rétt eins og Jón bróðir hans og árið 1965 kom út bókin Nokkur kvæði og
                    vísur eftir Sigurð.
                  </p>
                  <p>
                    Nokkrar vísur séra Sigurðar hafa orðið landfleygar, ekki síst hringhendan, sem hann orti á ensku, og
                    hentar vel til að útskýra ferskeytluformið og afbrigði þess fyrir útlendingum:
                  </p>
                </div>
                <blockquote style={{ paddingTop: '3rem', textAlign: 'center' }}>
                  <p>She is fine as morn in May,</p>
                  <p>Mild, divine and clever,</p>
                  <p>Like a shining summer day,</p>
                  <p>She is mine forever.</p>
                </blockquote>
                <div style={{ paddingTop: '3rem' }}>
                  <p>Séra Sigurður lést árið 1971.</p>
                </div>
              </div>
            </>
          ) : (
            <>
              <h1>History</h1>
              <div>
                <div>
                  <p>
                    Pastor Sigurður Norland, the brother of Jón Norland (the grandfather of the present owners of
                    Hindisvík), was well-known during his lifetime and numerous people still remember him.
                  </p>
                  <p>
                    Sigurður bought Hindisvík from his mother, Helga Björnsdóttir, in the spring of 1919. The parish of
                    Tjörn in Vatnsnes was entrusted to him at the end of 1922.
                  </p>
                  <p>
                    Sigurður started the preservation of seals in the 1940s, but he was also known for his large herd of
                    horses of the so-called Hindisvík breed, which was recognised all over the country. However,
                    systematic breeding of horses in Hindisvík stopped many years ago.
                    <strong>Now there are no longer any horses of the Hindisvík breed in Hindisvík.</strong>
                  </p>
                </div>
                <div>
                  <p>
                    Sigurður had a poetic vein just like his brother Jón Norland. In 1965, Sigurður published a book
                    called Nokkur kvæði og vísur (A Few Poems and Quatrains).
                  </p>
                  <p>
                    Some of Sigurður´s quatrains have gained popularity nationwide; among them is the “hringhenda“ that
                    he wrote in English and which is well-suited to demonstrate the metre of “ferskeytla“ and its
                    variations to people who do not speak Icelandic:
                  </p>
                </div>
                <blockquote style={{ paddingTop: '3rem', textAlign: 'center' }}>
                  <p>She is fine as morn in May,</p>
                  <p>Mild, divine and clever,</p>
                  <p>Like a shining summer day,</p>
                  <p>She is mine forever.</p>
                </blockquote>
                <div style={{ paddingTop: '3rem' }}>
                  <p>The Reverend Sigurður Norland died in 1971.</p>
                </div>
              </div>
            </>
          )}
        </Details>
      </HindisvikWrapper>
    </Wrapper>
  );
};
